export const KitchenData = [
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k1.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k2.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k3.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k4.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k5.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k6.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k7.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k8.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k9.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k10.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k11.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k12.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k13.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k14.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k15.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k16.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k17.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k18.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k19.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k20.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k21.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k22.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k23.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k24.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k25.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k26.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k27.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k28.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k29.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k30.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k31.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k32.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k33.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k34.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k35.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k36.jpg"
    },
    {
        image:"https://raw.githubusercontent.com/Suleman3015/vogueimages/main/kitchen/k37.jpg"
    },

]