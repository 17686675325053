import React, { useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import "./navbar.css";
import { Link } from "react-router-dom";
import Vogue2 from "../../content/vogue.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NavbarHeadingSlider from "./NavbarHeadingSlider";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import PinterestIcon from '@mui/icons-material/Pinterest';
// import Tiktok from "../../content/icons/tik.png"


function Hamburger() {
  const [Open, setState] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSofas, setOpenSofas] = useState(null);
  const [sofaInnerOpen, setSofaInnerOpen] = useState(false);
  const [openWardrobes, setOpenWardrobes] = useState(null);
  const [wardrobesInnerOpen, setWardrobesInnerOpen] = useState(false);
  const [openBeds, setOpenBeds] = useState(null);
  const [bedsInnerOpen, setBedsInnerOpen] = useState(false);
  const [openShelves, setOpenShelves] = useState(null);
  const [shelvesInnerOpen, setShelvesInnerOpen] = useState(false);
  const [openDining, setOpenDining] = useState(null);
  const [diningInnerOpen, setDiningInnerOpen] = useState(false);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log(anchorEl);
  const handleSofaClick = (event) => {
    setOpenSofas(event.currentTarget);
    setSofaInnerOpen(true);
  };

  const handleSofaInnerClose = () => {
    setOpenSofas(null);
    setSofaInnerOpen(false);
  };

  const handleWardrobesClick = (event) => {
    setOpenWardrobes(event.currentTarget);
    setWardrobesInnerOpen(true);
  };

  const handleWardrobesInnerClose = () => {
    setOpenWardrobes(null);
    setWardrobesInnerOpen(false);
  };

  const handleBedsClick = (event) => {
    setOpenBeds(event.currentTarget);
    setBedsInnerOpen(true);
  };

  const handleBedsInnerClose = () => {
    setOpenBeds(null);
    setBedsInnerOpen(false);
  };

  const handleShelvesClick = (event) => {
    setOpenShelves(event.currentTarget);
    setShelvesInnerOpen(true);
  };

  const handleShelvesInnerClose = () => {
    setOpenShelves(null);
    setShelvesInnerOpen(false);
  };

  const handleDiningClick = (event) => {
    setOpenDining(event.currentTarget);
    setDiningInnerOpen(true);
  };

  const handleDiningInnerClose = () => {
    setOpenDining(null);
    setDiningInnerOpen(false);
  };

  const handleChange = (toValue) => {
    console.log(toValue);
    setState(false);

    setOpenSofas(false);
    setSofaInnerOpen(false);
    setOpenWardrobes(false);
    setWardrobesInnerOpen(false);
    setOpenBeds(false);
    setBedsInnerOpen(false);
    setOpenShelves(false);
    setShelvesInnerOpen(false);
    setOpenDining(false);
    setDiningInnerOpen(false);
    // window.location.pathname = toValue;
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    //changes the function state according to the value of open
    setState(open);
    event.stopPropagation();
  };

  return (
    <>
      <div className="hamburgerContainer">
        <NavbarHeadingSlider />
        <Container maxWidth="lg" disableGutters="true">
          <Toolbar
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              paddingRight: "0px",
            }}
          >
            <Box
              edge="start"
              color="inherit"
              aria-label="open drawer"
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row-reverse",
                width: "100%",
                alignItems:"center"
              }}
              sx={{
                mr: 2,
                display: {
                  md: "block",
                },
              }}
            >
              <Link to="/" className="logoImg">
                <img height={80} src={Vogue2} alt="furniture" />
              </Link>

              <div style={{ display: "flex", alignItems: "self-end" }}>
                <a href="tel:03185896448">
                  <LocalPhoneIcon
                    style={{
                      color: "black",
                    }}
                    sx={{ fontSize: "30px" }}
                  />
                  {/* <img alt="vogue wood"   style={{marginRight: matches === true ? "3vw" :"0.5vw",cursor:"pointer"}} height={25} src={Phone} /> */}
                </a>
          
                <MenuIcon
                  onClick={toggleDrawer(true)}
                  sx={{ fontSize: "40px" }}
                />
              
              </div>
            </Box>

            {/* The outside of the drawer */}
            <div style={{ backgroundColor: "white"}}>
              <Drawer
                PaperProps={{
                  sx: {
                    backgroundColor: "white",
                    width: "100%",
                  },
                }}
                //from which side the drawer slides in
                anchor="right"
                //if open is true --> drawer is shown
                open={Open}
                //function that is called when the drawer should close
                onClose={toggleDrawer(false)}
                //function that is called when the drawer should open
                onOpen={toggleDrawer(true)}
              >
                {/* The inside of the drawer */}
                <Box
                  sx={{
                    p: 2,
                    height: 1,
                    // backgroundColor: "#dbc8ff"
                  }}
                  className="hamburgerMenu"
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.19)" }}
                >
                  {/* when clicking the icon it calls the function toggleDrawer and closes the drawer by setting the variable open to false */}
                  <IconButton sx={{ mb: 2 }}>
                    <CloseIcon onClick={toggleDrawer(false)} />
                  </IconButton>

                  <Divider sx={{ mb: 2 }} />

                  <Box sx={{ mb: 2 }}>
                    <Box className="menuLogoContianer">
                      <MenuItem onClick={handleBedsClick}>
                        <Link
                          className="naviMenu"
                          aria-controls={
                            bedsInnerOpen ? "beds-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={bedsInnerOpen ? "true" : undefined}
                        >
                          Bedroom <ArrowDropDownIcon />
                        </Link>
                      </MenuItem>
                      <Menu
                        sx={{ paddingTop: "20px" }}
                        id="beds-menu"
                        anchorEl={openBeds}
                        open={bedsInnerOpen}
                        onClose={handleBedsInnerClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem>
                          <Link
                            to="/kingsize"
                            onClick={() => handleChange("/kingsize")}
                            className="naviMenu"
                          >
                            King Size Beds
                          </Link>
                        </MenuItem>

                        <MenuItem>
                          <Link
                            to="/queensize"
                            onClick={() => handleChange("/queensize")}
                            className="naviMenu"
                          >
                            Queen Size Beds
                          </Link>
                        </MenuItem>

                        <MenuItem>
                          <Link
                            to="/Traditionalsize"
                            onClick={() => handleChange("/traditionalsize")}
                            className="naviMenu"
                          >
                            Traditional
                          </Link>
                        </MenuItem>

                        <MenuItem>
                          <Link
                            to="/youth"
                            onClick={() => handleChange("/youth")}
                            className="naviMenu"
                          >
                            Youth
                          </Link>
                        </MenuItem>
                      </Menu>

                      <MenuItem onClick={handleShelvesClick}>
                        <span
                          className="naviMenu"
                          aria-controls={
                            shelvesInnerOpen ? "shelves-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={shelvesInnerOpen ? "true" : undefined}
                        >
                          Bookshelves <ArrowDropDownIcon />
                        </span>
                      </MenuItem>
                      <Menu
                        sx={{ paddingTop: "20px" }}
                        id="shelves-menu"
                        anchorEl={openShelves}
                        open={shelvesInnerOpen}
                        onClose={handleShelvesInnerClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem>
                          <Link
                            to="/standardbookshelves"
                            onClick={() => handleChange("/standardbookshelves")}
                            className="naviMenu"
                          >
                            Standard
                          </Link>
                        </MenuItem>

                        <MenuItem>
                          <Link
                            to="/kidsBookshelves"
                            onClick={() => handleChange("/kidsbookshelves")}
                            className="naviMenu"
                          >
                            Kids
                          </Link>
                        </MenuItem>
                      </Menu>

                      <MenuItem onClick={handleSofaClick}>
                        <span
                          className="naviMenu"
                          aria-controls={
                            sofaInnerOpen ? "sofa-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={sofaInnerOpen ? "true" : undefined}
                        >
                          Living <ArrowDropDownIcon />
                        </span>
                      </MenuItem>
                      <Menu
                        sx={{ paddingTop: "20px" }}
                        id="sofa-menu"
                        anchorEl={openSofas}
                        open={sofaInnerOpen}
                        onClose={handleSofaInnerClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem>
                          <Link
                            to="/fabricsofas"
                            onClick={() => handleChange("/fabricsofas")}
                            className="naviMenu"
                          >
                            Fabric Sofas
                          </Link>
                        </MenuItem>

                        <MenuItem>
                          <Link
                            to="/rexinesofas"
                            onClick={() => handleChange("/rexinesofas")}
                            className="naviMenu"
                          >
                            Rexine Sofas
                          </Link>
                        </MenuItem>
                      </Menu>

                      <MenuItem onClick={handleDiningClick}>
                        <span
                          className="naviMenu"
                          aria-controls={
                            diningInnerOpen ? "dining-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={diningInnerOpen ? "true" : undefined}
                        >
                          Dining <ArrowDropDownIcon />
                        </span>
                      </MenuItem>
                      <Menu
                        sx={{ paddingTop: "20px" }}
                        id="dining-menu"
                        anchorEl={openDining}
                        open={diningInnerOpen}
                        onClose={handleDiningInnerClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem>
                          <Link
                            to="/DiningFormal"
                            onClick={() => handleChange("/diningformal")}
                            className="naviMenu"
                          >
                            Formal
                          </Link>
                        </MenuItem>

                        <MenuItem>
                          <Link
                            to="/DiningCasual"
                            onClick={() => handleChange("/diningcasual")}
                            className="naviMenu"
                          >
                            Casual
                          </Link>
                        </MenuItem>
                      </Menu>

                      <MenuItem onClick={handleWardrobesClick}>
                        <span
                          className="naviMenu"
                          aria-controls={
                            wardrobesInnerOpen ? "wardrobes-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={
                            wardrobesInnerOpen ? "true" : undefined
                          }
                        >
                          Wardrobes <ArrowDropDownIcon />
                        </span>
                      </MenuItem>
                      <Menu
                        sx={{ paddingTop: "20px" }}
                        id="wardrobes-menu"
                        anchorEl={openWardrobes}
                        open={wardrobesInnerOpen}
                        onClose={handleWardrobesInnerClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem>
                          <Link
                            to="/2DOORSWARDROBES"
                            onClick={() => handleChange("/2doorswardrobes")}
                            className="naviMenu"
                          >
                            2 Doors Wardrobes
                          </Link>
                        </MenuItem>

                        <MenuItem>
                          <Link
                            to="/3DOORSWARDROBES"
                            onClick={() => handleChange("/3doorswardrobes")}
                            className="naviMenu"
                          >
                            3 Doors Wardrobes
                          </Link>
                        </MenuItem>

                        <MenuItem>
                          <Link
                            to="4DOORSWARDROBES"
                            onClick={() => handleChange("/4doorswardrobes")}
                            className="naviMenu"
                          >
                            4 Doors Wardrobes
                          </Link>
                        </MenuItem>

                        <MenuItem>
                          <Link
                            to="/SlidingWardrobes"
                            onClick={() => handleChange("/slidingwardrobes")}
                            className="naviMenu"
                          >
                            Sliding Wardrobes
                          </Link>
                        </MenuItem>
                      </Menu>
                      <MenuItem onClick={handleClose}>
                        <Link
                          to="/Dressers"
                          onClick={() => handleChange("/dressers")}
                          className="naviMenu"
                        >
                          {" "}
                          Dressers{" "}
                        </Link>
                      </MenuItem>

                      <MenuItem>
                        <Link
                          className="naviMenu"
                          to="/Study-ComputerTables"
                          onClick={() => handleChange("/study-computertables")}
                        >
                          {" "}
                          Tables
                        </Link>
                      </MenuItem>
                    </Box>

                    {/* <h1>hello world</h1> */}
                    <div className="footerMenuContainerSocialIcon" >
                      <p
                        style={{
                          fontWeight: "bold",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        FOLLOW US
                      </p>

                      <div style={{
                      display:"flex",
                      // alignItems:"center",
                      justifyContent:"center",
                      gap:"10px"

                    }}>
                        <a href=" https://www.facebook.com/voguewoodstore">
                          <FacebookIcon
                            style={{ color: "black" }}
                            className="footerCopyright"
                          />
                        </a>

                        <a href="https://www.instagram.com/voguewoodstore/?igshid=YmMyMTA2M2Y=">
                          <InstagramIcon
                            style={{ color: "black" }}
                            className="footerCopyright"
                          />
                        </a>
               <a href=" https://www.youtube.com/@VogueWoodStore">
                        <YouTubeIcon
                          style={{ color: "black" }}
                          className="footerCopyright"
                        />
                        </a>
                        {/* </a> */}
                        <a href=" https://twitter.com/voguewoodstore">
                          <TwitterIcon
                            style={{ color: "black" }}
                            className="footerCopyright"
                          />
                        </a>
                        <a href="http://www.pinterest.com/VogueWoodStore" >
                        <PinterestIcon
                        style={{ color: "black" }}
                        className="footerCopyright"
                        />
                        </a>
                        {/* <a href="https://www.tiktok.com/@voguewoodstore">
                          <img height={16} width={16} src={Tiktok} alt="voguewood tiktok"/>
                        </a> */}
                      </div>
                    </div>
                  </Box>
                </Box>
              </Drawer>
            </div>
          </Toolbar>
        </Container>
      </div>
    </>
  );
}

export default Hamburger;
