import React from "react";
import "./footer.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Divider from "@mui/material/Divider";
import TwitterIcon from "@mui/icons-material/Twitter"
import Vogue from "../../content/voguewood.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import PinterestIcon from '@mui/icons-material/Pinterest';
import TiktokW from "../../content/icons/tiktokWeb.png"
// 

function Footer() {
  const matches = useMediaQuery("(max-width:750px)");
  return (
    <div className="footer">
      <div className="FooterMain">
        <div className="FooterMenu">
          {/* container */}
          <div className="footerMenuContainer">
            <img alt="voguewood logo" src={Vogue} height={180} />
            <div></div>
          </div>
          <div className="optionalRow">
            <div className="footerMenuContainer">
              {/* <h2> Contact Us</h2> */}
              <a className="Textnone" href="/privacypolicy">
                {" "}
                Privacy Policy
              </a>
              <a className="Textnone" href="/faqs">
                {" "}
                FAQs
              </a>
              {/* <p> <MarkEmailReadRoundedIcon/>thevoguewood@gmail.com</p> */}
            </div>

            <div className="footerMenuContainer">
              {/* <h2>Navigations</h2> */}
              {/* <a> <HomeRoundedIcon/> Home</a> */}
              <a className="Textnone" href="/contact">
                {" "}
                Contact Us
              </a>
              <a className="Textnone" href="/about">
                About Us
              </a>
            </div>

            {matches === true ? (
              <div className="footerMenuContainerSocialIcon">
              <p style={{
                fontWeight:"bold",
                marginTop:"10px",
                marginBottom:"10px"
              }}>FOLLOW US</p>

              <div>
              <a
               href="https://m.facebook.com/Thevoguewood/"
              >
                <FacebookIcon
                  style={{ color: "black" }}
                  className="footerCopyright"
                />
                </a>

                <a
                 href="https://www.instagram.com/voguewoodstore/?igshid=YmMyMTA2M2Y="
                >
                <InstagramIcon
                  style={{ color: "black" }}
                  className="footerCopyright"
                />
                </a>
                <a href="https://www.youtube.com/@VogueWoodStore">
                <YouTubeIcon
                  style={{ color: "black" }}
                  className="footerCopyright"
                />
                </a>
                <a
                     href="https://twitter.com/voguewoodstore"
                >
                <TwitterIcon
                  style={{ color: "black" }}
                  className="footerCopyright"
                />
                
                </a>

                <a href="http://www.pinterest.com/VogueWoodStore" >
                        <PinterestIcon
                        style={{ color: "black" }}
                        className="footerCopyright"
                        />
                        </a>
                       
              </div>
              </div>
            ) : null}
          </div>
        </div>

        <Divider />

        <div className="FooterBottom">
          <p>
            We specialize in high-quality home furniture, décor, office
            furniture, and living solutions at an affordable price point. Our
            skilled craftsmen take great care to ensure that each piece of
            furniture is made with the utmost precision and attention to detail.
            With strong relationships with top wood providers in Pakistan and
            our own handcrafted factory, we are dedicated to delivering your
            dream furniture on order without compromising on quality.
          </p>

          {/* <div>
          <h2> Follow Us</h2>
          <div>
            <FacebookIcon />
            <InstagramIcon />
            <YouTubeIcon />
          </div>
          </div> */}
        </div>
      </div>
      <div className="FooterEnd">
        <div className="footerEndAlign">
          <footer
            style={{ color: "white", fontSize: "12px" }}
            className="footerCopyright"
          >
            &copy; Copyright 2024 VogueWood
          </footer>

          {matches === false ? (
            <div className="socialMediaFooter">
            <a
               href="https://m.facebook.com/Thevoguewood/"
              >
              <FacebookIcon
                style={{ color: "white" }}
                className="footerCopyright"
              />
              </a>

              <a
                 href="https://www.instagram.com/voguewoodstore/?igshid=YmMyMTA2M2Y="
                >
              <InstagramIcon
                style={{ color: "white" }}
                className="footerCopyright"
              />
              </a>

              <a href="https://www.youtube.com/@VogueWoodStore">
              <YouTubeIcon
                style={{ color: "white" }}
                className="footerCopyright"
              />
              </a>
                 <a
                     href="https://twitter.com/voguewoodstore"
                >
                <TwitterIcon
                  style={{ color: "white" }}
                  className="footerCopyright"
                />
                </a>
                <a href="http://www.pinterest.com/VogueWoodStore" >
                        <PinterestIcon
                        style={{ color: "white" }}
                        className="footerCopyright"
                        />
                        </a>
                        <a         className="footerCopyright" href="https://www.tiktok.com/@voguewoodstore">
                          <img height={23} width={23}  src={TiktokW} alt="voguewood tiktok"/>
                        </a>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Footer;
