import React, { useEffect } from "react";
import "./categories.css";
import { Typography } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import Aos from "aos";
import "aos/dist/aos.css";
import { categoriesStatic } from "../../static/categories.static";
import { Link } from "react-router-dom";

function Categories() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const matches = useMediaQuery("(max-width:600px)");

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div data-aos="fade-up" data-aos-duration="500" className="main">
      {matches ? (
        <h2
          style={{
            fontFamily: "'Alegreya SC', serif",
            margin: "auto auto 25px auto",
          }}
          className="mainTypo"
        >
          Procure By Categories
        </h2>
      ) : (
        <h1
          style={{
            fontFamily: "'Alegreya SC', serif",
            margin: "auto auto 25px auto",
          }}
          className="mainTypo"
        >
          Procure By Categories
        </h1>
      )}

      {matches === true ? (
        <div
          style={{
            width: "90%",
            margin: "auto",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            gap: "30px",
          }}
        >
          {categoriesStatic.map((categories) => {
            return (
              <Link
                to={`/${categories.navigate}`}
                style={{
                  width: "45%",
                  textDecoration: "none",
                  borderRadius: "5px",
                  position: "relative",
                }}
              >
                <div style={{ height: "100%", borderRadius: "5px" }}>
                  <div style={{ borderRadius: "5px" }} className="boxes">
                    {/* <Typography
                  style={{
                    fontSize: "25px",
                    letterSpacing: "1px",
                    fontFamily: "'Alegreya SC', serif",
                  }}
                  className="Typo"
                >
                  {" "}
                  {categories.Head}
                </Typography> */}
                    <img
                      alt="furniture"
                      style={{ borderRadius: "5px" }}
                      className="img"
                      src={categories.img}
                    />
                  </div>
                </div>
                <Typography
                  style={{
                    fontSize: "14px",
                    letterSpacing: "1px",
                    textDecoration: "none",
                    textAlign: "center",

                    marginTop: "4px",
                    fontWeight: "bold",
                    position: "absolute",
                    top: "50%",
                    left: "0",
                    right: "0",
                    color: "white",

                    fontFamily: "'Alegreya SC', serif",
                  }}
                  // className="Typo"
                >
                  {" "}
                  {categories.Head}
                </Typography>
              </Link>
            );
          })}
        </div>
      ) : (
        <Carousel responsive={responsive}>
          {categoriesStatic.map((categories) => {
            return (
              <Link to={`/${categories.navigate}`}>
                <div style={{ overflow: "hidden", height: "100%" }}>
                  <div className="boxes">
                    <Typography
                      style={{
                        fontSize: "25px",
                        letterSpacing: "2px",
                        fontFamily: "'Alegreya SC', serif",
                      }}
                      className="Typo"
                    >
                      {" "}
                      {categories.Head}
                    </Typography>
                    <img alt="furniture" className="img" src={categories.img} />
                  </div>
                </div>
              </Link>
            );
          })}
        </Carousel>
      )}

      {/* </div> */}
    </div>
  );
}

export default Categories;
