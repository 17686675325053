import React from "react";
import Hero from "../../components/hero/hero";
import Categories from "../../components/categoriesGallery/categories";
import TopProducts from "../../components/topProducts/topProducts";
import Section from "../../components/section/section";
import Timelines from "../../components/timeline/timeline";
import Feedback from "../../components/feedback/feedback";
import "./home.css";
import Lines from "../../content/lines3.png";
// import useMediaQuery from "@mui/material/useMediaQuery";
import ContactUs from "../../components/Form/form";
import { SnackbarProvider } from "notistack";
import BrandWeCarry from "../../components/brandWeCarry/brandWeCarry";
import Value from "../../components/value/value";
import Experience from "../../components/experience/experience";
import WhyUs from "../../components/whyus";
import WhatsappButton from "../../components/layout/whatsappButton";

function Home() {
  // const matches = useMediaQuery("(max-width:600px)");
  return (
    <div className="homeRoot">
      <Hero />
      <Experience />
      <Categories />

      <Value />
      <TopProducts />

      <Section />
      <div className="lines">
        <img alt="vogue wood" className="lineImg" src={Lines} />
      </div>
      <Timelines />

      <WhyUs />

      <BrandWeCarry />
      <Feedback />

      <SnackbarProvider maxSnack={3}>
        <ContactUs />
      </SnackbarProvider>
      <WhatsappButton />
    </div>
  );
}

export default Home;
