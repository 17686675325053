import React from 'react'
import Carousel from "react-multi-carousel";
import "./navbar.css"
import useMediaQuery from "@mui/material/useMediaQuery";

function NavbarHeadingSlider() {
    const matches = useMediaQuery("(max-width:800px)");
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 3000, min: 2300 },
          items: 1,
        },
        smalldesktop: {
          breakpoint: { max: 1400, min: 1024 },
          items: 1,
        },
        desktop: {
          breakpoint: { max: 2300, min: 1400 },
          items: 1,
        },
        tablet: {
          breakpoint: { max: 1024, min: 1024 },
          items: 1,
        },
        mobile: {
          breakpoint: { max: 700, min: 0 },
          items: 1,
        },
      };
    
      const Static = [
        {
          title: "Enjoy up to 40% off on selected items",
        },
        {
          title: " Create a welcoming atmosphere with our furniture",
        },
    
        {
          title: "  Unwrap your dream home with free delivery",
        },
      ];
  return (
    <div className="HeadLines">
    <div className="navbarSliderDiv">
      <Carousel
        infinite={true}
        removeArrowOnDeviceType={[
          "tablet",
          "mobile",
          "desktop",
          "smalldesktop",
          "superLargeDesktop",
        ]}
        autoPlay="true"
        autoPlaySpeed={3000}
        responsive={responsive}
      >
        {Static.map((Title) => {
          return (
            <p
              style={{
                color: "white",
                width: `{ ${matches === true ? "80%": "50%" }}`,
                margin: "auto",
                textAlign: "center",
                letterSpacing:`{ ${matches === true ? "0px": "1px" }}`,
                fontSize:`{ ${matches === true ? "12px": "18px" }}`
              }}
            >
              {Title.title}
            </p>
          );
        })}
      </Carousel>
    </div>
  </div>
  )
}

export default NavbarHeadingSlider