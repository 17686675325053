import React from "react";
import Navbar from "../navbar/navbar";
import "./layout.css";
import Footer from "../Footer/footer";
function Layout({ children }) {
  return (
    <div style={{
      // backgroundColor:"#f5f5f5"
    }}>
      <Navbar />
      {children}

      {/* <WhatsappButton /> */}
      <Footer />
      {/* <Whatsapp/> */}
    </div>
  );
}

export default Layout;
