import React, { useState } from "react";
import "./item.css";
import Items from "../../components/items";
import WhatsappButton from "../../components/layout/whatsappButton";

function Item() {
  const [MainCategory, setMainCategory] = useState("");

  return (
    <div className="ItemMain">
      <div className="itemSide">
        <h1 className="catHead">{MainCategory}</h1>

        <Items MainCategory={setMainCategory} />
      </div>
      <WhatsappButton />
    </div>
  );
}

export default Item;
