import Brand1 from "../content/brands/brand1.png";
import Brand2 from "../content/brands/brand2.png";
import Brand3 from "../content/brands/brand3.jpg";
import Brand4 from "../content/brands/brand4.png";
import Brand5 from "../content/brands/brand5.png";
import Brand6 from "../content/brands/brand6.png";
import Brand7 from "../content/brands/brand7.png";
import Brand8 from "../content/brands/brand8.png";

export const BrandsList = [
  {
    img: Brand8,
  },
  {
    img: Brand6,
  },
  {
    img: Brand4,
  },
  {
    img: Brand3,
  },
  {
    img: Brand5,
  },
  {
    img: Brand1,
  },
  {
    img: Brand7,
  },
  {
    img: Brand2,
  },
];
