import React, { useEffect, useState } from "react";
import HeroSlider, { Slide, Nav } from "hero-slider";
import "./product.css";
import Button from "../../components/reusable-componnent/button/index";
// import Rating from "@mui/material/Rating";
import { Link, useParams } from "react-router-dom";
import { VoguewoodProducts } from "../../static/products.static";
import useMediaQuery from "@mui/material/useMediaQuery";
import ShoppingCartSharpIcon from "@mui/icons-material/ShoppingCartSharp";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WhatsappButton from "../../components/layout/whatsappButton";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import WhyUs from "../../components/whyus";
import ModeCommentSharpIcon from "@mui/icons-material/ModeCommentSharp";

const bulletStatic = [
  {
    head: "Payment Policy",
    title:
      "We require a 50% advance payment at the time of placing the order. The remaining 50% is due before delivery.",
  },
  {
    head: "Order Cancellation Policy",
    title:
      "All orders can be canceled within 24 hours of placing the order. After that, cancellation will not be possible as we begin production immediately.",
  },
  {
    head: "Refund Policy",
    title:
      "We do not offer refunds on orders that have already been processed and are in production.",
  },
  {
    head: "Shipping Policy",
    title:
      " Delivery times may vary based on the type of furniture and the customization options selected. We will provide an estimated delivery date at the time of order confirmation.",
  },
  {
    head: "Warranty Policy",
    title:
      "Our furniture comes with a 1-year warranty that covers any defects in material or workmanship. If you experience any issues with your furniture during this period, please contact us immediately to arrange for a repair. We are committed to ensuring your satisfaction and will work with you to resolve the issue as soon as possible.",
  },
];

function Product() {
  const [message, setMessage] = useState("");
  const matches = useMediaQuery("(max-width:700px)");
  const param = useParams();
  const productParam = param.product;
  const items = param.items;
  const [imageFullView, setImageFullView] = useState();
  const [fullImage, setFullImage] = useState(false);

  const topApproach = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const filterProduct = VoguewoodProducts.filter((items) => {
    return (
      items?.productName?.replace(/\s/g, "").toLowerCase() === productParam
    );
  });

  const SuggestedProduct = VoguewoodProducts.filter((item) => {
    return (
      item?.category?.replace(/\s/g, "").toLowerCase() ===
      filterProduct[0]?.category?.replace(/\s/g, "").toLowerCase()
    );
  });

  const finalSuggested = SuggestedProduct.filter((item) => {
    return (
      item?.suggestedThisProduct === true &&
      item?.productName !== filterProduct[0].productName
    );
  });

  console.log(finalSuggested, "finalSuggested");

  const imagesofproducts = filterProduct[0]?.images;

  useEffect(() => {
    const currentUrl = window.location.href;
    setMessage(
      `Hi, I'm interested in purchasing ${filterProduct[0]?.productName} for ${filterProduct[0]?.discountedPrice}. Product Category ${filterProduct[0]?.category} Product link: ${currentUrl} `
    );
  }, [filterProduct, setMessage]);

  const ImageClicked = (index) => {
    setImageFullView(index);
    setFullImage(true);
  };

  console.log(imagesofproducts[imageFullView], "imgimg");
  return (
    <>
      <div className="productDetail">
        <div className="productDetailSlider">
          {/* for slider */}
          <HeroSlider
            height={matches === true ? "40vh" : "100vh"}
            autoplay
            controller={{
              initialSlide: 1,
              slidingDuration: 200,
              slidingDelay: 50,
              onSliding: (nextSlide) =>
                console.debug("onSliding(nextSlide): ", nextSlide),
              onBeforeSliding: (previousSlide, nextSlide) =>
                console.debug(
                  "onBeforeSliding(previousSlide, nextSlide): ",
                  previousSlide,
                  nextSlide
                ),
              onAfterSliding: (nextSlide) =>
                console.debug("onAfterSliding(nextSlide): ", nextSlide),
            }}
          >
            {imagesofproducts.map((images, key) => {
              return (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => ImageClicked(key)}
                >
                  <div className="outOfStock">
                    <p>{filterProduct[0].sp} OFF </p>
                    <ShoppingCartSharpIcon />
                  </div>
                  <Slide
                    label="Giau Pass - Italy"
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img

                    alt="vogueWoodd"
                      src={images}
                     
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Slide>
                </div>
              );
            })}

            <Nav />
          </HeroSlider>
        </div>
        <div className="ProductAbout">
          <div className="prdocutAboutHeader">
            <p className="productBioCategory">{filterProduct[0].subCategory}</p>
            <div className="vogueWoodArticle">
              <p> {filterProduct[0].productArticle}</p>
            </div>
          </div>
          <div className="productBioDivider">
            <Divider className="divi" />
          </div>
          {/* for detail */}
          <h2 className="productAboutName">{filterProduct[0].productName}</h2>
          <div className="productBioDivider">
            <Divider className="divi" />
          </div>
          {/* <Rating style={{}} name="read-only" value={5} readOnly /> */}
          <div className="productArticleTag"></div>
          {/* <div> */}

          <p className="productDes">
            <span>Description:</span>
            {filterProduct[0].productDescription}
          </p>
          {filterProduct[0].size ? (
            <div className="Productsize">
              <span>Size:</span>
              <p>{filterProduct[0].size}</p>
            </div>
          ) : null}

          <div className="productBioDivider">
            <div className="productPrice">
              <p className="cutPrice">
                <s>Rs {filterProduct[0].actualPrice}</s>
              </p>{" "}
              <p className="productPriceCategory">
                Rs {filterProduct[0].discountedPrice}
              </p>
            </div>
          </div>

          {filterProduct[0].package ? (
            <div className="Productsize">
              {/* <span>Package:</span> */}
              <p
                style={{
                  fontWeight: "bold",
                }}
              >
                {filterProduct[0].package}
              </p>
            </div>
          ) : null}

          {filterProduct[0].category === "bed" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <span className="includes">Set Includes </span>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  marginBottom: "10px",
                }}
              >
                {filterProduct[0]?.includes?.map((includes) => {
                  return (
                    <div className="includesBoxes">
                      <p>{includes}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
          {filterProduct[0].sofa ? (
            <>
              <h3
                style={{
                  marginTop: "20px",
                  marginBottom: "10px",
                  textAlign: "center",
                }}
              >
                Choose Your Seating Option
              </h3>
              <Accordion
                style={{
                  backgroundColor: "#F5F5F5",
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                  // marginTop:"20px"
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Sofa
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <Typography
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography> */}
                  <p className="productPriceCategory">
                    Rs {filterProduct[0].sofa}
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  backgroundColor: "#F5F5F5",
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                  marginBottom: "10px",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Loveseat
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <Typography
                    style={{
                      marginBottom: "10px",S
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography> */}
                  <p className="productPriceCategory">
                    Rs {filterProduct[0].loveseat}
                  </p>
                  <p
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    Size {filterProduct[0]?.loveseatSize}
                  </p>
                </AccordionDetails>
              </Accordion>
            </>
          ) : (
            <div className="productBioDivider">
              <Divider className="divi" />
            </div>
          )}

          <div
            style={{
              width: "100%",
              margin: "auto",
              paddingTop: "20px",
              paddingBottom: "20px",
              display: "flex",
            }}
          >
            <ModeCommentSharpIcon style={{}} />
            <p
              className="alertPopUp"
              style={{
                textAlign: "justify",
                marginLeft: "5px",
              }}
            >
              Please note that all our furniture is made-to-order, and we
              require a 50% advance payment at the time of placing the order.
              The remaining 50% is due before delivery. Thank you for your
              understanding.
            </p>
          </div>

          <a
            href={`https://api.whatsapp.com/send?phone=923185896448&text=${encodeURIComponent(
              message
            )}`}
          >
            <Button
              style={{
                cursor: "pointer",
              }}
              href={`https://api.whatsapp.com/send?phone=923185896448&text=${encodeURIComponent(
                message
              )}`}
              Wid={"100%"}
              Text="Order Now"
            />
          </a>

          {/* </div> */}
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          gap: "25px",
        }}
      >
        {bulletStatic.map((bullet) => {
          return (
            <div
              style={
                {
                  // display:"flex"
                }
              }
            >
              <h3>{bullet.head}</h3>
              <p className="alertPopUpBullet">{bullet.title}</p>
            </div>
          );
        })}
      </div>

      <WhyUs />
      <WhatsappButton data={filterProduct} />

      <div className="productSuggestedSection">
        <h2>Items Suggested For You</h2>
        <div className="itemsitemSideChild">
          {finalSuggested.map((filterProducts, key) => {
            const imagesofproducts = filterProducts?.images;
            return (
              <Link
                style={{
                  color: "black",
                  textDecoration: "none",
                  position: "relative",
                }}
                key={key}
                onClick={topApproach}
                to={`/${items}/${filterProducts?.productName
                  .replace(/\s/g, "")
                  .toLowerCase()}`}
              >
                <div
                  style={{
                    justifyContent: `${
                      filterProducts?.includes ? "space-between" : "flex-start"
                    }`,
                  }}
                  className="itemsitemBox"
                >
                  <div className="productsPageSavings">
                    <p>{filterProducts?.sp}</p>
                    <ShoppingCartSharpIcon
                      sx={{
                        fontSize: "12px",
                      }}
                    />
                  </div>
                  <div className="itemsitemImageCont">
                    <img
                      height={280}
                      className="itemsimgBox"
                      src={imagesofproducts[0]}
                      alt="vogue wood beds"
                    />
                  </div>
                  <div className="itemsitemDetail">
                    <div className="itemsdetailChild1">
                      <p>{filterProducts?.productName}</p>
                    </div>
                    <div className="itemsdetailChild2">
                      {/* <Rating name="read-only" value={5} readOnly /> */}
                      <div className="itemsdetailPrice">
                        <h3 className="itemspriceText">
                          Rs {filterProducts?.discountedPrice}
                        </h3>
                        <p>
                          <s>Rs {filterProducts?.actualPrice}</s>
                        </p>{" "}
                      </div>
                    </div>
                  </div>

          
                </div>
              </Link>
            );
          })}
        </div>
      </div>

      {fullImage === true ? (
        <div className="fullImageView">
          <div className="fullImageCancel">
            <CancelSharpIcon
              style={{ color: "white", cursor: "pointer" }}
              onClick={() => setFullImage(false)}
            />
          </div>
          <img
            alt="vogue wood product"
            src={imagesofproducts[imageFullView]}
            className="kitchenImage"
            width="100%"
            height="100%"
          />
        </div>
      ) : null}
    </>
  );
}

export default Product;
