import React, { useEffect } from "react";
// import Rating from "@mui/material/Rating";
import "./index.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { VoguewoodProducts } from "../../static/products.static";
import { Link } from "react-router-dom";

import ShoppingCartSharpIcon from "@mui/icons-material/ShoppingCartSharp";

function TopDealsHome() {
  const TopDeals = VoguewoodProducts.filter((item) => {
    return item?.topDeal === true;
  });

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <div
        data-aos-duration="1000"
        data-aos="fade-up"
        className="itemsitemSideChild"
      >
        {TopDeals.map((TopDealProduct, key) => {
          const imagesofproducts = TopDealProduct?.images;
          return (
            <Link
              style={{
                color: "black",
                textDecoration: "none",
              }}
              to={`/${TopDealProduct?.subCategory
                .replace(/\s/g, "")
                .toLowerCase()}/${TopDealProduct?.productName
                .replace(/\s/g, "")
                .toLowerCase()}`}
            >
              <div
                style={{
                  justifyContent: `${
                    TopDealProduct?.includes ? "space-between" : "flex-start"
                  }`,
                }}
                className="itemsitemBox"
              >
                <div className="productsPageSavings">
                  <p>{TopDealProduct?.sp}</p>
                  <ShoppingCartSharpIcon
                    sx={{
                      fontSize: "12px",
                    }}
                  />
                </div>
                <div className="itemsitemImageCont">
                  <img
                    height={280}
                    className="itemsimgBox"
                    src={imagesofproducts[0]}
                    alt="vogue wood beds"
                  />
                </div>
                <div className="itemsitemDetail">
                  <div className="itemsdetailChild1">
                    <p>
                      {TopDealProduct?.productName &&
                        TopDealProduct?.productName
                          .split(" ")
                          .slice(0, 20)
                          .join(" ")}
                      {TopDealProduct?.productName &&
                        TopDealProduct?.productName.split(" ").length > 20 &&
                        "..."}
                    </p>
                    {/* <Rating name="read-only" value={5} readOnly /> */}
                  </div>
                  <div className="itemsdetailChild2">
                    {/* <p className="itemsarticle"> 41B28</p> */}
                    {/* <Rating name="read-only" value={5} readOnly /> */}
                    <div className="itemsdetailPrice">
                      <h3 className="itemspriceText">
                        Rs {TopDealProduct?.discountedPrice}
                      </h3>
                      <p>
                        <s>Rs {TopDealProduct?.actualPrice}</s>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </>
  );
}

export default TopDealsHome;
