import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import "./modal.css";
import { KitchenData } from "../../static/kitchen.static";
import BUTTON from "../reusable-componnent/button";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Modal({ open, setOpen }) {
  const [fullImage, setFullImage] = useState(false);
  const [imageKey,setImageKey] = useState()
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const feature = [
    {
      head: "Determine Your Needs",
      title:
        "The first step in customizing your kitchen is to assess your needs. Consider how you use your kitchen, how many people will be using it, and what you need to store. This will help you determine the size and layout of your kitchen, as well as the types of cabinets, appliances, and other features you need.",
    },
    {
      head: "Choose Your Layout",
      title:
        "Your kitchen's layout is one of the most important decisions you'll make when customizing it. Common layouts include U-shaped, L-shaped, galley, and single-wall kitchens. Think about how you move around your kitchen and choose a layout that will allow you to work efficiently and comfortably.",
    },
    {
      head: "Select Your Countertops",
      title:
        "Countertops are a key part of your kitchen and are available in a variety of materials, including granite, quartz, laminate, and more. Choose a material that fits your budget and is durable enough to withstand the wear and tear of daily use.",
    },
    {
      head: "Choose Your Appliances",
      title:
        "Appliances are essential to the functionality of your kitchen, so choose carefully. Consider factors such as energy efficiency, size, and style when selecting your appliances. Customizing your appliances can also help you create a cohesive look in your kitchen.",
    },
    {
      head: "Add Personal Touches",
      title:
        "Finally, personalize your kitchen with your own unique touches. This can include adding custom hardware, backsplashes, lighting, or artwork. Adding personal touches will help create a space that is uniquely yours and reflective of your style and personality. In conclusion, customizing your kitchen allows you to create a functional and attractive space that meets your specific needs and style. Start by determining your needs, choosing your layout, selecting your cabinets and countertops, picking your appliances, and adding personal touches to create a kitchen that is uniquely yours",
    },
  ];

  function imageClicked(key) {
    setFullImage(true);
    setImageKey(key)
  }

  console.log(fullImage, "full image");

  // function Change(key){
  //   console.log(key,'key')
  // }
  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button> */}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        style={
          {
            // filter:"brightness(20%)"
          }
        }
      >
        <AppBar
          sx={{
            position: "sticky",
            background:
              "linear-gradient(257deg,rgba(122, 83, 12, 1) 0%,rgba(0, 0, 0, 1) 100%)",
          }}
        >
          <Toolbar style={{
            // position:"sticky"
          }}>
            <IconButton
              edge="start"
              onClick={handleClose}
              aria-label="close"
              sx={{ color: "white" }}
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              VogueWood
            </Typography>
          </Toolbar>
        </AppBar>

        <div className="kitchenModal">
          <div className="KitchenHeadingContainer">
            <h2>Customizing Your Kitchen for a Unique and Functional Space</h2>
            <p style={{
              textAlign:"center",
              fontSize:"14px"
            }}>
              When it comes to kitchen design, one size does not fit all.
              Whether you're renovating an existing kitchen or building a new
              one, customizing it to fit your needs and style is essential for
              creating a functional and attractive space. Here are some tips to
              help you get started on your kitchen customization journey.
            </p>
          </div>

          <div className="kitchenFeatureText">
            <div className="headingFeature">
              <h3>Pick Your Cabinets</h3>
            </div>
            <p>
              Cabinets are the backbone of your kitchen and play a big role in
              its overall look and feel. Customizing your cabinets gives you the
              freedom to choose the style, color, and material that fits your
              personal taste and kitchen needs.
            </p>
          </div>

          <div className="gridKitchenContainer">
            {KitchenData.map((kitchen, key) => {
              return (
                <div kitchen={kitchen} key={key} className="gridKitchenBox">
                  <img
                    className="kitchenImage"
                    height={250}
                    src={kitchen.image}
                    onClick={() => imageClicked(key)}
                    style={{ cursor: "pointer" }}
                    alt="vogue wood "
                  />
                  <a
                    href={`https://api.whatsapp.com/send?phone=923185896448&text=${encodeURIComponent(
                      `Hi, I'm interested in ${KitchenData[key].image} `
                    )}`}
                  >
                    <BUTTON
                      Text="Request A Quote"
                      style={{
                        cursor: "pointer",
                      }}
                      Wid={"100%"}
                    />
                  </a>
                </div>
              );
            })}
          </div>
          <div className="featureKitchen">
            {feature.map((feature) => {
              return (
                <div className="kitchenFeatureText">
                  <div className="headingFeature">
                    <h3>{feature.head}</h3>
                  </div>
                  <p>{feature.title}</p>
                </div>
              );
            })}
          </div>
          {fullImage === true ? (
            <div className="fullImageView">
              <div className="fullImageCancel">
                <CancelSharpIcon
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={() => setFullImage(false)}
                />
              </div>
              <img
                alt="vogue wood"
                src={KitchenData[imageKey].image}
                className="kitchenImage"
                width="100%"
                height="100%"
              />
            </div>
          ) : null}
        </div>
      </Dialog>
    </div>
  );
}

export default Modal;
