import React, { useState } from "react";
import "./navbar.css";
import Vogue from "../../content/vogue.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import useMediaQuery from "@mui/material/useMediaQuery";
import Hamburger from "./hamburger";
import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NavbarHeadingSlider from "./NavbarHeadingSlider";


function Navbar() {
  const matches = useMediaQuery("(max-width:800px)");
  const [anchorEl, setAnchorEl] = useState(null);
  // const [anchorInner, setAnchorInner] = useState(null);

  const [openSofas, setOpenSofas] = useState(null);
  const [sofaInnerOpen, setSofaInnerOpen] = useState(false);
  const [openWardrobes, setOpenWardrobes] = useState(null);
  const [wardrobesInnerOpen, setWardrobesInnerOpen] = useState(false);
  const [openBeds, setOpenBeds] = useState(null);
  const [bedsInnerOpen, setBedsInnerOpen] = useState(false);
  const [openShelves, setOpenShelves] = useState(null);
  const [shelvesInnerOpen, setShelvesInnerOpen] = useState(false);
  const [openDining, setOpenDining] = useState(null);
  const [diningInnerOpen, setDiningInnerOpen] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log(anchorEl);
  const handleSofaClick = (event) => {
    setOpenSofas(event.currentTarget);
    setSofaInnerOpen(true);
  };

  const handleSofaInnerClose = () => {
    setOpenSofas(null);
    setSofaInnerOpen(false);
  };

  const handleWardrobesClick = (event) => {
    setOpenWardrobes(event.currentTarget);
    setWardrobesInnerOpen(true);
  };

  const handleWardrobesInnerClose = () => {
    setOpenWardrobes(null);
    setWardrobesInnerOpen(false);
  };

  const handleBedsClick = (event) => {
    setOpenBeds(event.currentTarget);
    setBedsInnerOpen(true);
  };

  const handleBedsInnerClose = () => {
    // setOpenBeds(false);
    setBedsInnerOpen(false);
 
  };

  const handleShelvesClick = (event) => {
    setOpenShelves(event.currentTarget);
    setShelvesInnerOpen(true);
  };

  const handleShelvesInnerClose = () => {
    setOpenShelves(null);
    setShelvesInnerOpen(false);
  };

  const handleDiningClick = (event) => {
    setOpenDining(event.currentTarget);
    setDiningInnerOpen(true);
  };

  const handleDiningInnerClose = () => {
    setOpenDining(null);
    setDiningInnerOpen(false);
  };

  const handleChange = (toValue) => {
    console.log(toValue);
    // window.location.pathname = toValue;
  };

  return (
    <>
      {matches === true ? (
        <Hamburger />
      ) : (
        <div className="container">
          <NavbarHeadingSlider />
          <div className="semiContainer">
            <Link to="/">
              <img
                style={{
                  marginTop: "10px",
                  marginLeft: "20px",
                }}
                height={130}
                src={Vogue}
                alt="vogue wood , furniture site, pakistani furniture , pakistan furniture market , vogue wood furniture"
              />
            </Link>
            <div className="menu">
              <div className="menuOptions">
                <MenuItem onClick={handleBedsClick}>
                  <Link
                    className="naviMenu"
                    aria-controls={bedsInnerOpen ? "beds-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={bedsInnerOpen ? "true" : undefined}
                  >
                    Bedroom <ArrowDropDownIcon />
                  </Link>
                </MenuItem>
                <Menu
                  sx={{ paddingTop: "20px" }}
                  id="beds-menu"
                  anchorEl={openBeds}
                  open={bedsInnerOpen}
                  onClose={handleBedsInnerClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleBedsInnerClose}>
                    <Link
                      to="/kingsize"
                      onClick={() => handleChange("/kingsize")}
                      className="naviMenu"
                    >
                      King Size Beds
                    </Link>
                  </MenuItem>

                  <MenuItem onClick={handleBedsInnerClose}>
                    <Link
                      to="/queensize"
                      onClick={() => handleChange("/queensize")}
                      className="naviMenu"
                    >
                      Queen Size Beds
                    </Link>
                  </MenuItem>

                  <MenuItem onClick={handleBedsInnerClose}>
                    <Link
                      to="/Traditionalsize"
                      onClick={() => handleChange("/traditionalsize")}
                      className="naviMenu"
                    >
                      Traditional
                    </Link>
                  </MenuItem>

                  <MenuItem onClick={handleBedsInnerClose}>
                    <Link
                      to="/youth"
                      onClick={() => handleChange("/youth")}
                      className="naviMenu"
                    >
                      Youth
                    </Link>
                  </MenuItem>
                </Menu>

                <MenuItem onClick={handleShelvesClick}>
                  <span
                    className="naviMenu"
                    aria-controls={
                      shelvesInnerOpen ? "shelves-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={shelvesInnerOpen ? "true" : undefined}
                  >
                    Bookshelves <ArrowDropDownIcon />
                  </span>
                </MenuItem>
                <Menu
                  sx={{ paddingTop: "20px" }}
                  id="shelves-menu"
                  anchorEl={openShelves}
                  open={shelvesInnerOpen}
                  onClose={handleShelvesInnerClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleShelvesInnerClose}>
                    <Link
                      to="/standardbookshelves"
                      onClick={() => handleChange("/standardbookshelves")}
                      className="naviMenu"
                    >
                      Standard
                    </Link>
                  </MenuItem>

                  <MenuItem onClick={handleShelvesInnerClose}>
                    <Link
                      to="/kidsBookshelves"
                      onClick={() => handleChange("/kidsbookshelves")}
                      className="naviMenu"
                    >
                      Kids
                    </Link>
                  </MenuItem>
                </Menu>

                <MenuItem onClick={handleSofaClick}>
                  <span
                    className="naviMenu"
                    aria-controls={sofaInnerOpen ? "sofa-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={sofaInnerOpen ? "true" : undefined}
                  >
                    Living <ArrowDropDownIcon />
                  </span>
                </MenuItem>
                <Menu
                  sx={{ paddingTop: "20px" }}
                  id="sofa-menu"
                  anchorEl={openSofas}
                  open={sofaInnerOpen}
                  onClose={handleSofaInnerClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleSofaInnerClose}>
                    <Link
                      to="/fabricsofas"
                      onClick={() => handleChange("/fabricsofas")}
                      className="naviMenu"
                    >
                      Fabric Sofas
                    </Link>
                  </MenuItem>

                  <MenuItem onClick={handleSofaInnerClose}>
                    <Link
                      to="/rexinesofas"
                      onClick={() => handleChange("/rexinesofas")}
                      className="naviMenu"
                    >
                      Rexine Sofas
                    </Link>
                  </MenuItem>
                </Menu>

                <MenuItem onClick={handleDiningClick}>
                  <span
                    className="naviMenu"
                    aria-controls={diningInnerOpen ? "dining-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={diningInnerOpen ? "true" : undefined}
                  >
                    Dining <ArrowDropDownIcon />
                  </span>
                </MenuItem>
                <Menu
                  sx={{ paddingTop: "20px" }}
                  id="dining-menu"
                  anchorEl={openDining}
                  open={diningInnerOpen}
                  onClose={handleDiningInnerClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleDiningInnerClose}>
                    <Link
                      to="/DiningFormal"
                      onClick={() => handleChange("/diningformal")}
                      className="naviMenu"
                    >
                      Formal
                    </Link>
                  </MenuItem>

                  <MenuItem onClick={handleDiningInnerClose}>
                    <Link
                      to="/DiningCasual"
                      onClick={() => handleChange("/diningcasual")}
                      className="naviMenu"
                    >
                      Casual
                    </Link>
                  </MenuItem>
                </Menu>

                <MenuItem onClick={handleWardrobesClick}>
                  <span
                    className="naviMenu"
                    aria-controls={
                      wardrobesInnerOpen ? "wardrobes-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={wardrobesInnerOpen ? "true" : undefined}
                  >
                    Wardrobes <ArrowDropDownIcon />
                  </span>
                </MenuItem>
                <Menu
                  sx={{ paddingTop: "20px" }}
                  id="wardrobes-menu"
                  anchorEl={openWardrobes}
                  open={wardrobesInnerOpen}
                  onClose={handleWardrobesInnerClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleWardrobesInnerClose}>
                    <Link
                      to="/2DOORSWARDROBES"
                      onClick={() => handleChange("/2doorswardrobes")}
                      className="naviMenu"
                    >
                      2 Doors Wardrobes
                    </Link>
                  </MenuItem>

                  <MenuItem onClick={handleWardrobesInnerClose}>
                    <Link
                      to="/3DOORSWARDROBES"
                      onClick={() => handleChange("/3doorswardrobes")}
                      className="naviMenu"
                    >
                      3 Doors Wardrobes
                    </Link>
                  </MenuItem>

                  <MenuItem onClick={handleWardrobesInnerClose}>
                    <Link
                      to="4DOORSWARDROBES"
                      onClick={() => handleChange("/4doorswardrobes")}
                      className="naviMenu"
                    >
                      4 Doors Wardrobes
                    </Link>
                  </MenuItem>

                  <MenuItem onClick={handleWardrobesInnerClose}>
                    <Link
                      to="/SlidingWardrobes"
                      onClick={() => handleChange("/slidingwardrobes")}
                      className="naviMenu"
                    >
                      Sliding Wardrobes
                    </Link>
                  </MenuItem>
                </Menu>
                <MenuItem onClick={handleClose}>
                  <Link
                    to="/Dressers"
                    onClick={() => handleChange("/dressers")}
                    className="naviMenu"
                  >
                    {" "}
                    Dressers{" "}
                  </Link>
                </MenuItem>

                <MenuItem>
                  <Link
                    className="naviMenu"
                    to="/Study-ComputerTables"
                    onClick={() => handleChange("/study-computertables")}
                  >
                    {" "}
                    Tables
                  </Link>
                </MenuItem>
              </div>
            </div>

            <div className="icons">
              <a
                style={{
                  color: "black",
                }}
                href="https://www.facebook.com/voguewoodstore"
              >
                <FacebookIcon />
              </a>

              <a
                style={{
                  color: "black",
                }}
                href="https://www.instagram.com/voguewoodstore/?igshid=YmMyMTA2M2Y="
              >
                <InstagramIcon />
              </a>
              <a style={{
                  color: "black",
                }}
                href=" https://www.youtube.com/@VogueWoodStore" >
              <YouTubeIcon />
              </a>
              <a
                style={{
                  color: "black",
                }}
                href="https://twitter.com/voguewoodstore"
              >
                <TwitterIcon />
              </a>
              {/* <a style={{
                  color: "black",
                }}
                  href="http://www.pinterest.com/VogueWoodStore ">
                <PinterestIcon />
              </a> 
              <a href="https://www.tiktok.com/@voguewoodstore">
                <img src={Tiktok} height={24} width={24}/>
                </a> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
