import "./App.css";
import Layout from "./components/layout/layout";
import {Outlet , BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/home/home";
import Product from "./Pages/product/product";
import Item from "./Pages/item/item";
import Contact from "./Pages/contact/contact";
import About from "./Pages/About/about"
import Faqs from "./Pages/About/faqs";
import Privacy from "./Pages/About/privacy";
import PageNotFound from "./Pages/404/error";
// import { useParams } from "react-router-dom";


function App() {
  // const { slug } = useParams();
  return (
    <BrowserRouter>
      <Layout>
      <Routes>
  <Route exact path="/" element={<Home />} />
  <Route path="/contact" element={<Contact />} />
  <Route path="/about" element={<About />} />
  <Route path="/faqs" element={<Faqs />} />
  <Route path="/privacypolicy" element={<Privacy />} />
  <Route path="/:items/:product" element={<Product />} />
  <Route path="/:items" element={<Item />} />
  <Route path="/*" element={<Outlet />}>
            <Route  element={<PageNotFound />} />
          </Route>


</Routes>



        
      </Layout>
    </BrowserRouter>
  );
}

export default App;
