import React, { useState, useEffect } from "react";
// import Rating from "@mui/material/Rating";
import "./index.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { VoguewoodProducts } from "../../static/products.static";
import { useParams, Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import ShoppingCartSharpIcon from "@mui/icons-material/ShoppingCartSharp";

function Items(props) {
  const [visibleProducts, setVisibleProducts] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();
  const items = params.items;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [items]);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const filterItems = VoguewoodProducts.filter((item) => {
    const categoryMatch =
      item?.category?.replace(/\s/g, "").toLowerCase() === items?.toLowerCase();
    const subCategoryMatch =
      item?.subCategory?.replace(/\s/g, "").toLowerCase() ===
      items?.toLowerCase();
    return categoryMatch || subCategoryMatch;
  });

  useEffect(() => {
    // Update headlineState after render
    const filteredCategory = VoguewoodProducts.find(
      (item) => item.category.toLowerCase() === items.toLowerCase()
    );
    const filteredSubCategory = VoguewoodProducts.find(
      (item) =>
        item.subCategory.replace(/\s/g, "").toLowerCase() ===
        items.toLowerCase()
    );
    if (filteredCategory) {
      props.MainCategory(filteredCategory.category);
    } else if (filteredSubCategory) {
      props.MainCategory(filteredSubCategory.subCategory);
    }
  }, [items, filterItems, props]);

  // props.MainCategory(headlineState);

  const [ref, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    loadProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    if (inView && !isLoading) {
      loadMoreProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const loadProducts = () => {
    // Load the first page of products
    const firstPage = filterItems.slice(0, 6);
    setVisibleProducts(firstPage);
    setPageNumber(2);
  };

  const loadMoreProducts = () => {
    setIsLoading(true);
    const nextPage = filterItems.slice((pageNumber - 1) * 6, pageNumber * 6);
    setVisibleProducts((prevProducts) => [...prevProducts, ...nextPage]);
    setPageNumber(pageNumber + 1);
    setIsLoading(false);
  };



  return (
    <>
      <div
        data-aos-duration="1000"
        data-aos="fade-up"
        className="itemsitemSideChild"
      >
        {visibleProducts.map((filterProducts, key) => {
          const imagesofproducts = filterProducts?.images;
          return (
            <Link
              style={{
                color: "black",
                textDecoration: "none",
                position: "relative",
              }}
              key={key}
              to={`/${items}/${filterProducts?.productName
                .replace(/\s/g, "")
                .toLowerCase()}`}
            >
              <div
              style={{
                justifyContent:`${filterProducts?.includes ? "space-between":"flex-start"}`
              }}
               className="itemsitemBox">
                <div className="productsPageSavings">
                  <p>{filterProducts?.sp}</p>
                  <ShoppingCartSharpIcon
                    sx={{
                      fontSize: "12px",
                    }}
                  />
                </div>
                <div className="itemsitemImageCont">
                  <img
                    height={280}
                    className="itemsimgBox"
                    src={imagesofproducts[0]}
                    alt="vogue wood beds"
                  />
                </div>
                <div className="itemsitemDetail">
                  <div className="itemsdetailChild1">
                    <p>
                      {filterProducts?.productName &&
                        filterProducts?.productName
                          .split(" ")
                          .slice(0, 20)
                          .join(" ")}
                      {filterProducts?.productName &&
                        filterProducts?.productName.split(" ").length > 20 &&
                        "..."}
                    </p>
                  </div>
                  <div className="itemsdetailChild2">
                    {/* <p className="itemsarticle"> 41B28</p> */}

                    {/* <Rating name="read-only" value={5} readOnly /> */}
                    <div className="itemsdetailPrice">
                      <h3 className="itemspriceText">
                        Rs {filterProducts?.discountedPrice}
                      </h3>
                      <p>
                        <s>Rs {filterProducts?.actualPrice}</s>
                      </p>{" "}
                    </div>
                  </div>
                </div>
                {/* {
                    filterProducts?.includes ?
                    <div className="ItemIncludes">
                <div className="itemIncludesList">
                {
                  filterProducts?.includes.map((include) => {
                    return(
                      <div className="itemIncludesBox">
                        <p
                  className="itemIncludeTittle"
                        >{include}</p>
                      </div>
                    )
                  }) 
                }

                </div>


                </div>
                    
                    :null
                  } */}
           
              </div>
            </Link>
          );
        })}
        <div ref={ref}></div>
        {isLoading && <p>Loading...</p>}
      </div>
    </>
  );
}

export default Items;
