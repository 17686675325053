import React from "react";
import "./topProducts.css";
import "react-multi-carousel/lib/styles.css";
import "aos/dist/aos.css";
import TopDealsHome from "../items/TopDealsHome";
import useMediaQuery from "@mui/material/useMediaQuery";

function TopProducts() {
  const matches = useMediaQuery("(max-width:550px)");

  return (
    <div className="topProductsMain">
   
       {matches ? (
        <h2
          data-aos="fade-up"
          data-aos-duration="1000"
          style={{
            letterSpacing: "1px",
            fontWeight: "bold",
            fontFamily: "'Alegreya SC', serif",
            margin: "auto auto 20px auto",
            // textAlign: `${align}`,
            // color: `${boxColor}`,
            // color:"rgb(54, 69, 79)"
          }}
          className="mainTypo"
        >
          {/* {title} */}
        </h2>
      ) : (
        <h1
          data-aos="fade-up"
          data-aos-duration="1000"
          style={{
            letterSpacing: "1px",
            fontWeight: "bold",
            fontFamily: "'Alegreya SC', serif",
            margin: "auto auto 20px auto",
            // textAlign: `${align}`,
            // color: `${boxColor}`,
            // color:"rgb(54, 69, 79)"
          }}
          className="mainTypo"
        >
          {/* {title} */}
        </h1>
      )}

<TopDealsHome />

   
  
  
    </div>
  );
}

export default TopProducts;
