import React, { useEffect } from "react";
import "./timeline.css";
import Aos from "aos";
import "aos/dist/aos.css";
import LocalShippingSharpIcon from '@mui/icons-material/LocalShippingSharp';
import SupportAgentSharpIcon from '@mui/icons-material/SupportAgentSharp';
import Diversity1SharpIcon from '@mui/icons-material/Diversity1Sharp';

function Timelines() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="timeLineContainer">
      <div className="timelineMain">
        <div className="TimelinneBoxesSection">
          <div className="TimelineBox">
          <div style={{display:"flex",alignItems:"center"}}>
            <LocalShippingSharpIcon sx={{fontSize:"40px",marginRight:"14px",marginBottom:"10px"}}/>
            <h2>Fast & Free Delivery </h2>
            </div>
            <p>
              We understand that you want your furniture delivered quickly and
              without any additional stress. That's why we offer free and fast
              delivery service on all of our products. Whether you're ordering a
              new sofa, dining table, or bedroom set, you can count on us to
              deliver your purchase right to your door.
            </p>

            <p>
              Our delivery team is professional, efficient, and committed to
              ensuring that your furniture arrives in perfect condition. We take
              pride in providing a seamless delivery experience, and we will
              always go the extra mile to make sure you're completely satisfied
              with your purchase.{" "}
            </p>

            <p>
              So why wait? Order with Vogue Wood today and enjoy free and fast
              delivery on all of your furniture purchases.
            </p>
          </div>
        </div>

        <div className="TimelinneBoxesSectionEnd">
          <div className="TimelineBox">
          <div style={{display:"flex",alignItems:"center"}}>
          <SupportAgentSharpIcon sx={{fontSize:"40px",marginRight:"14px",marginBottom:"10px"}}/>
            <h2>Unbeatable Service</h2>
            </div>
            <p>
              We are dedicated to providing our customers with unbeatable
              service. Our commitment to excellence starts with our selection of
              high-quality furniture and extends to every aspect of the customer
              experience. Whether you need help choosing the right furniture for
              your home, have questions about your order, or require assistance
              with a product, our knowledgeable and friendly customer service
              team is here to help.
            </p>
            <p>
              We believe that customer satisfaction is the key to our success,
              and we strive to make every interaction a positive one. So, if
              you're looking for the best in furniture and service, look no
              further than Vogue Wood. Order with confidence and experience the
              difference for yourself!
            </p>
          </div>
        </div>

        <div className="TimelinneBoxesSection">
          <div className="TimelineBox">
          <div style={{display:"flex",alignItems:"center"}}>
          <Diversity1SharpIcon sx={{fontSize:"40px",marginRight:"14px",marginBottom:"10px"}}/>
            <h2>Get A Free Quote</h2>
            </div>
            Elevate Your Home's Style Today -
            <p>
              Our team is committed to helping you find the perfect furniture to
              create a space that's uniquely yours. From cozy sofa bedrooms to
              stunning dining tables, we've got you covered.
            </p>
            <p>
              To get started, simply give us a call or fill out our online form
              for a free quote. Our team of experts will work with you to
              understand your needs and preferences, and provide you with a
              personalized quote that fits your budget.
            </p>
            <p>
              So don't wait - reach out to us today and let's get started on
              creating a beautiful home along with a kitchen that you'll love.
            </p>
          </div>
        </div>

        {/* {timelineStatic.map((timeline) => {
          return (
            <div className="timeLineBox">
          
              <img
                alt="vogue wood"
                data-aos="fade-up"
                src={timeline.img}
                className="timelineImg"
              />

              <div data-aos="fade" className="timelineTypo">
                <h1 className="headingh2"> {timeline.head} </h1>
                <p className="timelinePara">{timeline.title}</p>
              </div>
            </div>
          );
        })} */}
      </div>
      {/* <div className="TimeLineBackground">
    
      </div> */}
    </div>
  );
}

export default Timelines;
