import React from "react";
import "./about.css";
import WhatsappButton from "../../components/layout/whatsappButton";

function Privacy() {
  const Static = [
    {
      title: "Information We Collect",
      para: "We collect personal information, such as name, address, email, and phone number, when you place an order or create an account on our website. We also collect information about your purchase history, including items purchased and payment methods used.",
    },
    {
      title: "Use of Information",
      para: "The personal information we collect is used to process your order, to provide customer service, and to  send you promotional materials. We may also use your information to improve our website and to customize your shopping experience.",
    },
    {
      title: "Information Sharing",
      para: "We do not sell or rent your personal information to third parties. We may share your information with  our partners for the purpose of processing your order or for other business purposes, but only as necessary to provide you with the products and services you have requested.",
    },
    {
      title: "Security",
      para: "We take security seriously and have implemented appropriate measures to protect the personal information we collect. Our website uses secure socket layer (SSL) encryption to protect your information during transmission, and we store your information on secure servers.",
    },
    {
      title: "Cookies",
      para: "Our website uses cookies, which are small text files stored on your computer, to improve your shopping experience. Cookies allow us to remember your preferences and to personalize your shopping experience.",
    },
    {
      title: "Your Privacy Rights",
      para: "You have the right to access and to update the personal information we have collected about you. You can do this by logging into your account or by contacting us.",
    },
    {
      title: "Changes to Privacy Policy",
      para: "Our privacy policy may change from time to time. Any changes will be posted on this page, so please check back periodically for updates. If you have any questions about our privacy policy, please contact us for assistance. We are committe to protecting your privacy and to providing you with the best possible shopping experience.",
    },
  ];

  return (
    <div className="MainAbout">
      <h1 className="AboutUsHeaderLine">PRIVACY POLICY</h1>
      <p style={{
        textAlign:"center",
        width:"80%",
        margin:"auto"
      }}>
        At <span style={{
          fontWeight:"bold"
        }}> Vogue Wood, </span>  we are committed to protecting the privacy and security
        of our customers. Our privacy policy outlines the types of personal
        information we collect, how we use it, and the steps we take to protect
        it.
      </p>
      {Static.map((content) => {
        return (
          <div className="ParagraphAboutContainer">
            <h1 
            style={{
              textAlign:"start"
            }}
            className="headingAbout">{content.title}</h1>
            <p className="ParaAbout">{content.para}</p>
          </div>
        );
      })}
      <WhatsappButton/>
    </div>
  );
}

export default Privacy;
