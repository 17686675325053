import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Whatsapp from "../../content/whatsapp.svg";
import "./layout.css";


function WhatsappButton(props) {
  const matches = useMediaQuery("(max-width:500px)")
       const Message ="Hi, I'm interested to connect with you"

  return (
    <div className="svg">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <a
          href={`https://api.whatsapp.com/send?phone=923185896448&text=${encodeURIComponent(
            Message
          )}`}
        >
          <img
            alt="vogue wood"
            style={{
              marginRight: matches === true ? "3vw" : "0.5vw",
              cursor: "pointer",
            }}
            height={matches === true ? 37 : 60}
            src={Whatsapp}
          />
        </a>
      </div>
    </div>
  );
}

export default WhatsappButton;
