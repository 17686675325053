import React from 'react'

function PageNotFound() {
  return (
    <div style={{height:"100vh",display:"flex",alignItems:"center",justifyContent:"center"}}>
    <div>
    <h1 style={{color:"black"}}>
    Error
    </h1>
    </div>
    </div>
  )
}

export default PageNotFound