import React from 'react'
import "./about.css"
import WhatsappButton from '../../components/layout/whatsappButton';

function Faqs() {
    const Static = [
        {
          title: "What are the payment options available for purchasing furniture?",
          para:"We accept Bank Transfer/Wire Transfers, cash, mobile banking.",
        },
        {
          title: "Can I see the furniture before purchasing?",
          para: "Currently we don’t have showroom where you can see and experience the furniture. But You can also check product dimensions and detailed images on our website.",
        },
        {
          title: "Can I customize furniture to my specific needs?",
          para:  "Yes, we offer customization options for certain furniture pieces, such as size, color, and fabric. Contact us for more details.",
        },
        {
            title: "How long does delivery take and what are the delivery options?",
            para: "We make all furniture on order; Delivery time varies based on your location as well along with the availability of the product. Standard delivery takes 10-15 business days. We also offer express and white-glove delivery options."
          },
          {
            title: "How can I maintain and clean my furniture?",
            para:  "We provide detailed care instructions for each product on our website. Generally, furniture should be cleaned with a soft, damp cloth and a mild cleaner. Avoid harsh chemicals and direct sunlight."
          },
          {
            title: "How can I contact customer service for any questions or concerns?",
            para:"You can contact us through email, phone, or WhatsApp chat on our website. Our customer service team is available (In Business) to assist you."
          },
          {
            title: "Are there any ongoing sales or promotions?",
            para:  "Yes, we offer promotions and discounts on selected products throughout the year. Check our website for the latest deals."
          },

          {
            title: "What is the process for assembling furniture that requires it?",
            para:  "Assembly instructions are provided with each product at the delivery time. Some products come pre-assembled, while others require minimal assembly. If you need assistance, please contact us for help."
          },

          {
            title: "What is the quality of your furniture like?",
            para:  "Our furniture is made from high-quality materials and is designed to meet or exceed industry standards."
          },

          {
            title: "Can I cancel my order?",
            para:  "The Production Starts After You Place Your order so the orders cannot be cancelled after 24 hours, so please call our staff with any questions you may have prior to ordering, we will ensure that you get exactly what you need."
          },
          {
            title: "What is your return policy?",
            para:  "We do not cater to returns, if you have any concerns, please contact us."
          },
      ];

      
  return (
    <div className="MainAbout">
      <h1 className="AboutUsHeaderLine">FAQ'S</h1>
    {Static.map((content) => {
      return (
        <div style={{
          display:"flex",
          flexDirection:"column",
          alignItems:"flex-start",
          justifyContent:"flex-start",
          marginTop:"100px",
          marginBottom:"0px"
        }} >
          <h1 
          style={{
            textAlign:"justify"
          }}
          className="headingAbout">{content.title}</h1>
          <p className="ParaAbout">{content.para}</p>
        </div>
      );
    })}
    <WhatsappButton/>
  </div>
  )
}

export default Faqs