import React from "react";
import "./about.css";
import WhatsappButton from "../../components/layout/whatsappButton";

const Static = [
  {
    title: "Welcome To Vogue Wood",
    para: "Where we specialize in creating elegant home furniture, office furniture, and living solutions. Our commitment to quality is second to none, and we take great pride in ensuring that each piece we create meets our high standards.",
  },
  {
    title: "Our Legacy",
    para: "Established in 2017, Vogue Wood has quickly become a trusted name in the furniture industry. With over 10 years of experience in carpentry and a team of skilled designers, we have completed numerous projects in Karachi, including a large-scale woodworking project.",
  },
  {
    title: "Our Production Facility",
    para: "Today, Vogue Wood serves thousands of retail and corporate customers nationwide, including major institutions such as banks, hospitals, schools, and retail outlets. We offer a complete range of furniture and accessories, including kitchens, doors, wardrobes, tables, and more.",
  },
];

function About() {
  return (
    <div className="MainAbout">
          <WhatsappButton/>
    <h1 className="AboutUsHeaderLine">ABOUT US</h1>
      {Static.map((content) => {
        return (
          <div className="ParagraphAboutContainer">
            <h1
             className="headingAbout">{content.title}</h1>
            <p className="ParaAbout">{content.para}</p>
          </div>
        );
      })}

    </div>
  );
}

export default About;
