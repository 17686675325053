import Bed from "../content/productGallery/small.jpg";
import Sofa from "../content/productGallery/sofa.jpg";
import Dinning from "../content/productGallery/dinning.jpg";
import Table from "../content/productGallery/table.jpg";
import Dresser from "../content/productGallery/dressing.jpg";
import Wardrobes from "../content/productGallery/wardrobes.jpg";
import Bookshelf from "../content/productGallery/bookshelf.jpg";

export const categoriesStatic = [
  {
    img: Bed,
    Head: "Bedroom",
    navigate: "bedroom",
  },
  {
    img: Sofa,
    Head: "Living",
    navigate: "Living",
  },
  {
    img: Dinning,
    Head: "Dining Tables",
    navigate: "dining",
  },
 
  {
    img: Table,
    Head: "Tables",
    navigate: "tables",
  },
  {
    img: Dresser,
    Head: "Dressers",
    navigate: "Dressers",
  },
  {
    img: Wardrobes,
    Head: "Wardrobes",
    navigate: "WARDROBES",
  },
  // {
  //     img:Bunked,
  //     Head:"Bunk Beds",

  // },
  {
    img: Bookshelf,
    Head: "Book Shelves",
    navigate: "BookShelves",
  },
  // {
  //     img:kitchen,
  //     Head:"Kitchen Cabinets",

  // },
];
