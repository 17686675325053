import React,{useEffect,useState} from "react";
import "./index.css";
import Button from "../reusable-componnent/button";
import useMediaQuery from "@mui/material/useMediaQuery";
import Aos from "aos";
import "aos/dist/aos.css";
import Modal from "../modal/modal"


function Experience() {
  const [open,setOpen] = useState(false)
  const matches = useMediaQuery("(max-width:500px)");
  const matcheTab = useMediaQuery("(max-width:1000px)");


  function onClicked(){
    console.log("clicked")
    setOpen(true)
  }

  console.log(open,"open")
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="Experience">
      <div 
       data-aos-duration="500"
      data-aos="fade-up" className="ExOne">
        <h2>Over 10 Years Of Experience</h2>
        <p>
        We are delighted to offer our services to homeowners who are seeking to renovate their kitchens.
        </p>

        <Button OnClick={onClicked} href="/" Wid={matches ? "60%" : matcheTab ? "50%": "30%" } Text="Style Your Kitchen"/>
      </div>
<Modal open={open} setOpen={setOpen} />
    </div>
  );
}

export default Experience;
